


// Process List Animation Variables
let currentProcessItem = 1;
let repHomeProcessStageItem = document.getElementsByClassName('repHomeProcessStageItem');
let repHomeProcessLayerCard = document.getElementsByClassName('repHomeProcessLayerCard');
let repHomeProcessLayerCardFillPath = document.getElementsByClassName('repHomeProcessLayerCardFillPath');
let repHomeProcessContent = document.getElementsByClassName('repHomeProcessContent');


init();


function init() {

    initProcessListAnimationListeners();
    initPartnersSection();
}

function initProcessListAnimationListeners() {

    Array.from(repHomeProcessStageItem).forEach((item) => {
        item.addEventListener('click', function() {
                // Remove Active Classes From All Items
                Array.from(repHomeProcessStageItem).forEach((item) => {
                    item.classList.remove('active');
                });
                item.classList.add('active');


                // Hide the Current Active Process Layer Card
                Array.from(repHomeProcessLayerCard).forEach((itemCard) => {
                    if (itemCard.dataset.id == currentProcessItem) {
                        itemCard.classList.remove('active');
                        let originalX = itemCard.dataset.x;
                        let originalY = itemCard.dataset.y;
                        let originalZ = itemCard.dataset.z;
                        if (itemCard.dataset.id == 5) {
                            itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 48;`);
                        } else {
                            itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 100;`);

                        }
                        console.log("Hiding Process Layer Card: " + itemCard.dataset.id);
                    }
                });

                // Update the Color
                Array.from(repHomeProcessLayerCardFillPath).forEach((itemPath) => {
                    if (itemPath.dataset.id == currentProcessItem) {
                        itemPath.setAttribute('fill', '#1B1B1B');
                    }
                });

                // Hide all of the contents
                Array.from(repHomeProcessContent).forEach((itemContent) => {
                    itemContent.classList.add('hidden');
                });

                // Get The Process Item
                currentProcessItem = item.dataset.id;
                console.log("Current Process Item: " + currentProcessItem);

                // Popout the Current Card
                Array.from(repHomeProcessLayerCard).forEach((itemCard) => {
                    if (itemCard.dataset.id == currentProcessItem) {
                        itemCard.classList.add('active');
                        let originalX = parseInt(itemCard.dataset.x) - 10;
                        let originalY = parseInt(itemCard.dataset.y) + 5;
                        let originalZ = parseInt(itemCard.dataset.z) - 35;
                        if (itemCard.dataset.id == 5) {
                            if (itemCard.classList.contains('isOffCentered')) {
                                originalX = parseInt(itemCard.dataset.x) + 19;
                                originalY = parseInt(itemCard.dataset.y) + 5;
                                originalZ = parseInt(itemCard.dataset.z) - 20;
                            }
                            itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 48;`);
                        } else {
                            itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 100;`);
                        }
                        console.log("Showing Process Layer Card: " + itemCard.dataset.id);

                    }
                });

                // Update the Color
                Array.from(repHomeProcessLayerCardFillPath).forEach((itemPath) => {
                    if (itemPath.dataset.id == currentProcessItem) {
                        itemPath.setAttribute('fill', '#CB0000');
                    }
                });

                // Hide all of the contents
                Array.from(repHomeProcessContent).forEach((itemContent) => {
                    if (itemContent.dataset.id == currentProcessItem) {
                        itemContent.classList.remove('hidden');
                    }
                });




            });
    });

    Array.from(repHomeProcessLayerCard).forEach((item) => {
        item.addEventListener('mouseenter', function() {
            // Remove Active Classes From All Items
            Array.from(repHomeProcessStageItem).forEach((item) => {
                item.classList.remove('active');
                if (item.dataset.id == this.dataset.id) {
                    item.classList.add('active');
                }
            });



            // Hide the Current Active Process Layer Card
            Array.from(repHomeProcessLayerCard).forEach((itemCard) => {
                if (itemCard.dataset.id == currentProcessItem) {
                    itemCard.classList.remove('active');
                    let originalX = itemCard.dataset.x;
                    let originalY = itemCard.dataset.y;
                    let originalZ = itemCard.dataset.z;
                    if (itemCard.dataset.id == 5) {
                        itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 48;`);
                    } else {
                        itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 100;`);

                    }
                    console.log("Hiding Process Layer Card: " + itemCard.dataset.id);
                }
            });

            // Update the Color
            Array.from(repHomeProcessLayerCardFillPath).forEach((itemPath) => {
                if (itemPath.dataset.id == currentProcessItem) {
                    itemPath.setAttribute('fill', '#1B1B1B');
                }
            });

            // Hide all of the contents
            Array.from(repHomeProcessContent).forEach((itemContent) => {
                itemContent.classList.add('hidden');
            });

            // Get The Process Item
            currentProcessItem = item.dataset.id;
            console.log("Current Process Item: " + currentProcessItem);

            // Popout the Current Card
            Array.from(repHomeProcessLayerCard).forEach((itemCard) => {
                if (itemCard.dataset.id == currentProcessItem) {
                    itemCard.classList.add('active');
                    let originalX = parseInt(itemCard.dataset.x) - 10;
                    let originalY = parseInt(itemCard.dataset.y) + 5;
                    let originalZ = parseInt(itemCard.dataset.z) - 35;
                    if (itemCard.dataset.id == 5) {
                        if (itemCard.classList.contains('isOffCentered')) {
                            originalX = parseInt(itemCard.dataset.x) + 19;
                            originalY = parseInt(itemCard.dataset.y) + 5;
                            originalZ = parseInt(itemCard.dataset.z) - 20;
                        }
                        itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 48;`);
                    } else {
                        itemCard.setAttribute('style', `--offset-x: ${originalX}px; --offset-y: ${originalY}px; --offset-z: ${originalZ}px; --size: 100;`);
                    }
                    console.log("Showing Process Layer Card: " + itemCard.dataset.id);

                }
            });

            // Update the Color
            Array.from(repHomeProcessLayerCardFillPath).forEach((itemPath) => {
                if (itemPath.dataset.id == currentProcessItem) {
                    itemPath.setAttribute('fill', '#CB0000');
                }
            });

            // Hide all of the contents
            Array.from(repHomeProcessContent).forEach((itemContent) => {
                if (itemContent.dataset.id == currentProcessItem) {
                    itemContent.classList.remove('hidden');
                }
            });




        });

    });

}

function initPartnersSection() {


}